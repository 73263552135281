@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.frame {
  max-width: 1440px;
  margin: 30px auto;
  width: calc(100% - 60px);
}

@media only screen and (max-width: 480px) {
  .frame {
    margin: 20px auto;
    width: calc(100% - 40px);
  }
}
